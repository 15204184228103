import Image1 from '../../assets/image-1.jpeg'
import Image2 from '../../assets/image-2.jpeg'
import Image3 from '../../assets/image-3.jpeg'

const developers = [
    {
        src: Image1,
        news: "Keterangan"
    },
    {
        src: Image2,
        news: "Keterangan"
    },
    {
        src: Image3,
        news: "Keterangan"
    }
]

export default developers