import React from 'react'
import CarouselHero from '../Carousel'

const SliderItem = () => {

    return (
        <CarouselHero />
    )
}

export default SliderItem